import React, { useContext } from "react";
import { AppContext } from "../../provider/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { ERROR, SUCCESS } from "../../constant";
import { employeeDetail } from "../../services/EmployeeService";
import { useTheme } from "../../ThemeContext";
import { generateToast } from "../../helper";
import { post } from "../../services/ServicesApi";
import "./styles/index.css";
import { useEffect } from "react";

const Login = ({ setAuth }) => {
  const { setUser, handleClearStorage, socket } = useContext(AppContext);
  const navigate = useNavigate();
  const themeToggle = useTheme();
  const { mode } = themeToggle.themeState;


  // Initialize Google One Tap (Googleyolo)
  useEffect(() => {
    // Check if Google Identity Services is loaded
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      // Automatically show the Google One Tap prompt
      window.google.accounts.id.prompt();
    }
  }, []); // Only re-run if login is not in progress

  // Handle Google One Tap login response
  const handleCredentialResponse = async (response) => {

    try {
      const res = await post(
        `${process.env.REACT_APP_API_HOST}/v1/auth/google-yolo`,
        { token: response.credential }
      );
      handleClearStorage();
      localStorage.setItem("token", res.data.accessToken);
      const employeeResponse = await employeeDetail(res.data.accessToken);
      if (employeeResponse?.message) {
        navigate("/login");
      } else {
        socket.emit("login_user", {
          ...(employeeResponse.employee || {}),
          socketId: socket.id,
        });
        setUser(employeeResponse.employee);
        setAuth(true);
        generateToast("Login Successful", SUCCESS);
      }
    } catch (error) {
      console.error("Error during Google login:", error);
      generateToast("Login failed. Please try again.", ERROR);
      navigate("/login");
    }
  };

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        const res = await post(
          `${process.env.REACT_APP_API_HOST}/v1/auth/google`,
          { code }
        );
        handleClearStorage();
        localStorage.setItem("token", res.data.accessToken);
        const employeeResponse = await employeeDetail(res.data.accessToken);
        if (employeeResponse?.message) {
          navigate("/login");
        } else {
          socket.emit("login_user", {
            ...(employeeResponse.employee || {}),
            socketId: socket.id,
          });
          setUser(employeeResponse.employee);
          setAuth(true);
          generateToast("Login Successful", SUCCESS);
        }
      } catch (error) {
        console.error("Error during Google login:", error);
        generateToast("Login failed. Please try again.", ERROR);
        navigate("/login");
      }
    },
    flow: "auth-code",
  });

  return (
    <>
      <div
        className={`login-screen-wrapper tw-bg-fixed tw-pt-[20vh] tw-w-full tw-h-screen ${
          mode || ""
        }`}
      >
        <div className=" tw-mx-auto">
          <div className="tw-rounded-xl tw-bg-gray-100 tw-dark:bg-[#151515] tw-w-[437px] tw-h-auto tw-py-[35px] tw-mx-auto">
            <div className="tw-flex tw-w-full tw-px-10 tw-flex-col tw-items-center tw-justify-center">
              <img
                src="../images/headerlogo.png"
                className="tw-h-16 tw-text-center"
                alt="Logo"
              />
              <h1 className="tw-text-2xl tw-font-poppins tw-dark:text-white tw-leading-9 tw-text-darkGrey tw-mt-4 tw-font-[600] tw-capitalize">
                Welcome Back!
              </h1>
              <p className="tw-text-base tw-dark:text-white tw-leading-9 tw-text-darkGrey">
                Please sign in to access your account
              </p>
              <button
                type="button"
                onClick={login}
                class="login-with-google-btn tw-mt-4"
              >
                Sign in with Google
              </button>
            </div>
          </div>
          <p className="tw-text-center tw-mt-4 tw-text-[#FFF]">
            <Link to="/privacy-policy" className="tw-text-[#FFF] tw-font-[500]">
              Privacy Policy
            </Link>
            {" | "}
            <Link
              to="/terms-of-service"
              className="tw-text-[#FFF] tw-font-[500]"
            >
              Terms of Service
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
