export const QUCIK_FILTER_LABELS = [
  { label: "Phantom Contracts", value: "phantomContracts" },
  { label: "Canceled Contracts", value: "canceledContracts" },
  { label: "Need Bar Calc", value: "needBarCalc" },
  { label: "Sent W/ Sign Date", value: "sentSigned" },
  { label: "In Progress W/ Signed Date", value: "inProgressSigned" },
  {
    label: "Sign/Paid/Unpaid/Reconciled - No SignDate",
    value: "othersWithNoSignDate",
  },
  { label: "Potential Duplicates", value: "potentialDuplicates" },
  { label: "Exact Duplicates", value: "exactDuplicates" },
  { label: "Signed Before Creation Date", value: "signedBeforeCreation" },
  {
    label: "After Event Date - Not Signed/Paid/Reconciled ",
    value: "signedAfterEvent",
  },
];

export const COL_LABELS = [
  { label: "Created Date", value: "createdAt" },
  { label: "Event Date", value: "startDate" },
  { label: "Client", value: "client_name" },
  { label: "Client Type", value: "client_type" },
  { label: "Sch/Reg", value: "region" },
  { label: "Sold By", value: "sold_by" },
  { label: "Created By", value: "created_by" },
  { label: "Amount", value: "amount" },
  { label: "Cost", value: "cost" },
  { label: "Profit $", value: "profit" },
  { label: "Profit Margin", value: "profitMargin" },
  { label: "Status", value: "contract_status" },
  { label: "Sign Date", value: "sign_date" },
  { label: "Invoiced", value: "invoiced" },
  { label: "hasChildren", value: "hasChildren" },
  { label: "Google", value: "sync" },
  { label: "Delete", value: "delete" },
];

export const USER_ROLES = [
  "Owner",
  "Admin",
  "Event Director Lead Auditor",
  "Event Director Lead",
  "Event Director",
  "Event Director Assistant",
  "Staffer",
  "Staffing Manager",
  "Associate",
  "Guest"
];


export const LIMIT_LABLE = [
  { label: "show 5", value: 5 },
  { label: "show 10", value: 10 },
  { label: "show 20", value: 20 },
  { label: "show 30", value: 30 },
  { label: "show 40", value: 40 },
  { label: "show 50", value: 50 },
];

export const getStatusColor = (cell) => {
  switch (cell?.status) {
    case "Canceled":
      return "rgba(155, 153, 153, 0.917)";
    case "Sent":
      return "#fbd75b88";
    case "Signed/Paid":
      if (cell?.signDate) {
        return "#51b74988";
      }
      break;
    case "Signed/Partially Paid":
      if (cell?.signDate) {
        return "#9370db88"; 
      }
      break;
    case "Signed/Unpaid":
      if (cell?.signDate) {
        return "#ff887c88";
      }
      break;
    case "Reconciled":
      return "#46d6db88";
    case "Postponed":
      return "#fbd75b88";
    default:
      return "#ffffff";
  }
};
