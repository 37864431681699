export const accessTypes = {
  contractManagement: {
    canReconciled: "canReconciled",
    canInvoiced: "canInvoiced",
  },

  statistics: {
    canViewClientProfile: "canViewClientProfile",
  },

  // Event Management Access Types
  eventManagement: {
    canEditSoldBy: "canEditSoldBy",
    canEditInvoice: "canEditInvoice",
    canEditStatus: "canEditStatus",
    canEditSignDate: "canEditSignDate",
    canSyncCalendar: "canSyncCalendar",
  },

  contractEditing: {
    canEditContract: "canEditContract",
    canDeleteContract: "canDeleteContract",
  },

  //   // Staffing Management Access Types
  //   staffingManagement: {
  //     canAssignStaff: "canAssignStaff",
  //     canRemoveStaff: "canRemoveStaff",
  //     canViewStaffingDetails: "canViewStaffingDetails",
  //   },

  //   // Reporting Access Types
  //   reports: {
  //     canViewReports: "canViewReports",
  //     canGenerateReports: "canGenerateReports",
  //   },
};
