import { accessTypes } from "./accessTypes";

export const roles = {
  owner: [
    ...Object.values(accessTypes.eventManagement),
    ...Object.values(accessTypes.contractManagement),
    ...Object.values(accessTypes.statistics),
    ...Object.values(accessTypes.contractEditing),  ],

  admin: [
    ...Object.values(accessTypes.eventManagement),
    ...Object.values(accessTypes.contractManagement),
    ...Object.values(accessTypes.statistics),
    ...Object.values(accessTypes.contractEditing),
  ],

  "event director lead auditor": [
    ...Object.values(accessTypes.statistics),
    ...Object.values(accessTypes.contractEditing),
  ],

  "event director lead": [
    ...Object.values(accessTypes.statistics),
    ...Object.values(accessTypes.contractEditing),
  ],

  "event director": [
    ...Object.values(accessTypes.statistics),
    ...Object.values(accessTypes.contractEditing),
  ],

  "event director assistant": [
    ...Object.values(accessTypes.statistics),
    ...Object.values(accessTypes.contractEditing),
  ],

  staffer: [...Object.values(accessTypes.statistics)],

  "staffing manager": [...Object.values(accessTypes.statistics)],

  associate: [...Object.values(accessTypes.statistics)],

  guest: [],
};
